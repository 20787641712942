<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "BusinessRuleActions.EditBusinessRuleAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && businessRuleIsOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && businessRuleIsOk"
    class="form-businessruleactionsendhttp-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-4">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "BusinessRuleActions.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          @keyup="nameKeyup()"
          id="Name"
          v-model="businessRuleActionData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-4">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "BusinessRuleActions.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control text-uppercase"
          id="FormulaName"
          disabled
          :value="businessRuleActionData.formulaName"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col col-md-4">
        <label for="BusinessActionType" class="form-label"
          >{{
            $t(
              "BusinessRuleActions.BusinessActionType",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          disabled
          id="BusinessActionType"
          v-model="businessRuleActionType"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-12 mt-3">
        <label for="Description" class="form-label">{{
          $t(
            "BusinessRuleActions.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="businessRuleActionData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="2"
        ></textarea>
      </div>
      <div class="col col-md-12 mt-3">
        <label for="ResponseText" class="form-label">{{
          $t(
            "BusinessRuleActions.ResponseText",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="ResponseText"
          class="form-control"
          id="ResponseText"
          v-model="businessRuleActionData.responseText"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div style="display: none" class="form-check isForcingSave">
        <label for="isForcingSave" class="form-label">{{
          $t(
            "BusinessRuleActions.IsForcingSave",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="isForcingSave"
          v-model="businessRuleActionData.isForcingSave"
        />
      </div>
      <div class="form-check isRunOnceForEachRecord">
        <label for="IsRunOnceForEachRecord" class="form-label">{{
          $t(
            "BusinessRuleActions.IsRunOnceForEachRecord",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsRunOnceForEachRecord"
          v-model="businessRuleActionData.isRunOnceForEachRecord"
        />
      </div>
      <div class="form-check">
        <label for="IsResponseReturnToClient" class="form-label">{{
          $t(
            "BusinessRuleActions.IsResponseReturnToClient",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsResponseReturnToClient"
          v-model="businessRuleActionData.isResponseReturnToClient"
        />
      </div>
      <div class="form-check">
        <label for="IsSynchAction" class="form-label">{{
          $t(
            "BusinessRuleActions.IsSynchAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsSynchAction"
          v-model="businessRuleActionData.isSynchAction"
        />
      </div>
      <div class="form-check">
        <label for="DoNotTriggerBusinessRule" class="form-label">{{
          $t(
            "BusinessRuleActions.DoNotTriggerBusinessRule",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="DoNotTriggerBusinessRule"
          v-model="businessRuleActionData.doNotTriggerBusinessRule"
        />
      </div>
      <div class="form-check">
        <label for="DownloadResponseAsDocument" class="form-label">{{
          $t(
            "BusinessRuleActions.DownloadResponseAsDocument",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="DownloadResponseAsDocument"
          v-model="businessRuleActionData.downloadResponseAsDocument"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BusinessRuleActions.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="businessRuleActionData.isActive"
        />
      </div>
      <div
        style="display: none"
        class="form-check isNotSendEmailIfThereIsNoRecord"
      >
        <label for="isForcingSave" class="form-label">{{
          $t(
            "BusinessRuleActions.IsNotSendEmailIfThereIsNoRecord",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsNotSendEmailIfThereIsNoRecord"
          v-model="businessRuleActionData.isNotSendEmailIfThereIsNoRecord"
        />
      </div>
      <div style="display: none" class="form-check isRunSeparatelyForEachUser">
        <label for="isRunSeparatelyForEachUser" class="form-label">{{
          $t(
            "BusinessRuleActions.IsRunSeparatelyForEachUser",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsRunSeparatelyForEachUser"
          v-model="businessRuleActionData.isRunSeparatelyForEachUser"
        />
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "BaseModelFields.CustomSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row row-cols-1 mt-3 mb-3">
          <div class="col col-md-12">
            <label for="method" class="form-label required">{{
              $t(
                "BusinessRuleActions.Method",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithLocalData"
              :allowEmpty="false"
              :selectedData="selectedMethod"
              :data="methodData"
              @onChange="onChangeForMethod"
            />
          </div>
          <div class="col col-md-12 mt-3">
            <label for="HTTPRequestAdress" class="form-label required">{{
              $t(
                "BusinessRuleActions.HTTPRequestAdress",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <textarea
              name="HTTPRequestAdress"
              class="form-control"
              id="HTTPRequestAdress"
              v-model="businessRuleActionData.httpRequestUrl"
              rows="2"
              required
            ></textarea>
          </div>
          <div class="col col-md-12 mt-3">
            <label for="HTTPHeader" class="form-label">{{
              $t(
                "BusinessRuleActions.HTTPHeader",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <textarea
              name="HTTPHeader"
              class="form-control"
              id="HTTPHeader"
              v-model="businessRuleActionData.httpRequestHeader"
              rows="2"
            ></textarea>
          </div>
          <template v-if="!hideHttpContent">
            <div class="col col-md-12 mt-3 httpRequestContentType">
              <label for="HTTPRequestContentType" class="form-label required">{{
                $t(
                  "BusinessRuleActions.HTTPRequestContentType",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :allowEmpty="false"
                :selectedData="selectedHTTPRequestContentType"
                :data="HTTPRequestContentTypeData"
                @onChange="onChangeForHTTPRequestContentType"
              />
            </div>
            <div class="col col-md-12 mt-3 httpContent">
              <label for="HTTPContent" class="form-label">{{
                $t(
                  "BusinessRuleActions.HTTPContent",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <textarea
                name="HTTPContent"
                class="form-control"
                id="HTTPContent"
                v-model="businessRuleActionData.httpRequestContent"
                rows="2"
              ></textarea>
            </div>
          </template>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectEditBusinessRuleActionSendHttpRequest",
  data() {
    return {
      detailRequestEnd: false,
      businessRuleIsOk: false,
      methodData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Get",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.Post",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.Put",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedMethod: [],
      HTTPRequestContentTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.AllRecordAsDto",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SelectedRecordValues",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.Custom",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedHTTPRequestContentType: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      hideHttpContent: false,
      disabledButtonClasses: ".btn-save",
      errors: [],
      createRecordFieldFormulasForEdit: [],
      businessRuleActionData: {},
      model: {},
      selectedCustomObjectCreate: [],
      selectedCustomObjectUpdate: [],
      businessRuleActionType: "",
      businessRuleTriggerTypeId: null,
      notificationType: 1,
      AttachementId: null,
      sourceSelect: "User",
      userList: "",
      userGroupList: "",
      permissionGroupList: "",
      fieldsType: "Email|FormulaOrganizationalUnit",
      businessActionObject: [],
      detailForwardOrEditForwardType: false,
      multipleSelect: this.$t(
        "Fields.MultipleSelect",
        {},
        { locale: this.$store.state.activeLang }
      ),
      select: this.$t(
        "Fields.Select",
        {},
        { locale: this.$store.state.activeLang }
      ),
      table: this.$t(
        "Fields.Table",
        {},
        { locale: this.$store.state.activeLang }
      ),
    };
  },
  methods: {
    onChangeForMethod: function (selected) {
      this.businessRuleActionData.httpRequestVerb = selected.value;
      if (selected.key == "2" || selected.key == "3") {
        this.hideHttpContent = false;
      } else {
        this.businessRuleActionData.httpRequestContentType = "";
        this.hideHttpContent = true;
      }
    },
    nameKeyup() {
      if (String.isNullOrWhiteSpace(this.businessRuleActionData.name)) {
        this.businessRuleActionData.formulaName = "";
        return;
      }
      this.businessRuleActionData.formulaName = String.replaceAlphanumeric(
        this.businessRuleActionData.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.businessRuleActionData.formulaName = String.replaceAlphanumeric(
        this.businessRuleActionData.formulaName,
        "_"
      ).toUpperCase();
    },
    onChangeForHTTPRequestContentType: function (selected) {
      this.businessRuleActionData.httpRequestContentType = selected.key;
    },
    getCustomObjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomObjectGet?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.businessActionObject = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-businessruleactionsendhttp-edit");
      form.addClass("was-validated");
      if (
        !this.$root.brActionValidity(form[0]) ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-BusinessRuleActionUpdateActionSendHTTPRequest", {
          ...this.businessRuleActionData,
        })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/BusinessRuleActions/" +
                this.$route.params.customObjectId +
                "&businessRuleId=" +
                this.$route.params.businessRuleId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getBusinessRuleActionData() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-BusinessRuleActionGet?id={0}&coId={1}&businessRuleId={2}",
            this.$route.params.businessRuleActionId,
            this.$route.params.customObjectId,
            this.$route.params.businessRuleId
          )
        )
        .then((response) => {
          this.businessRuleIsOk = response.data.isOk;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            this.businessRuleActionData = response.data.businessRuleAction;

            var keyVerb;
            if (this.businessRuleActionData.httpRequestVerb == "Get") {
              keyVerb = 1;
              this.hideHttpContent = true;
            } else if (this.businessRuleActionData.httpRequestVerb == "Post") {
              keyVerb = 2;
              this.hideHttpContent = false;
            } else if (this.businessRuleActionData.httpRequestVerb == "Put") {
              keyVerb = 3;
              this.hideHttpContent = false;
            }
            this.selectedMethod.push({
              key: keyVerb,
              value: this.businessRuleActionData.httpRequestVerb,
            });

            this.selectedHTTPRequestContentType.push({
              key: this.businessRuleActionData.httpRequestContentType.value,
              value:
                this.businessRuleActionData.httpRequestContentType.displayName,
            });
            this.businessRuleActionData.httpRequestContentType =
              response.data.businessRuleAction.httpRequestContentType.value;
          } else {
            this.errors.push(response.data.message);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getCustomObjectDetail();
    this.getBusinessRuleActionData();
    this.businessRuleTriggerTypeId = localStorage.getItem(
      "businessRuleTriggerTypeId"
    );
    this.businessRuleActionType = localStorage.getItem(
      "businessRuleActionType"
    );
  },
};
</script>
